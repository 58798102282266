@use "../../variables.scss" as *;

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05), 0 0 4px rgba(0, 0, 0, 0.15);
  padding: 10px 0;
  background-color: $base-bg;

  .header-title {
    color: $accent-color;
  }

  .dx-toolbar.header-toolbar {
    padding-right: $content-padding;

    .user-button.authorization {
      margin-left: 5px;

      .dx-button-content {
        padding: 0;
        height: 100%;
      }
    }

    .messages {
      padding-left: 5px;
      position: relative;

      .dx-badge {
        display: block;
        position: absolute;
        background-color: red;
        color: white;
        right: -10%;
        top: -10%;
        font-size: 12px;
        padding: 0 4px;
      }
    }

    .dx-toolbar-item.menu-button {
      width: $side-panel-min-width;
      text-align: center;
      padding: 0;

      .dx-icon {
        color: $accent-color;
      }
    }
  }
}
