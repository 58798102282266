.email-generator-popup-v2 {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  overflow-y: auto;
  height: 100%;
}

.messages-container {
  flex: 1;
  overflow-y: auto;
  width: '100%';
  background-color: #ededed;
  padding: 10px;
  border-radius: 5px;

}

.message {
  cursor: pointer;
}

.history {
  font-size: 1.5em;
  color: #ccc;
  margin-left: 10px;
  margin-top: 10px;
}

.insert-pane {
  width: 100%;
  margin-top: 5px;
}

.email-viewer {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  height: 100%;
}

.email-generator-popup-v2 .dx-textbox {
  width: 100%;
  border-radius: 5px;
  margin-top: 5px;
}

.email-generator-popup-v2 .allotment {
  border-radius: 5px;
}
