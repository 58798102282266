.email-client {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  padding: 5%;
  max-width: 100%;
  overflow-y: auto;
  height: 100%;
}

.email-client__field {
  display: flex;
  flex-direction: column;
  font-weight: bolder;
}

.email-client__title {
  font-size: 40px;
  font-weight: bolder;
  align-self: center;
  margin-bottom: 10px;
}

.email-client__label {
  font-weight: bolder;
  margin-bottom: 5px;
}

.email-client__input {
  padding: 5px;
  font-weight: 400;
  height: max-content;
  border: 1px solid #ccc;
}
.email-client__textarea {
  padding: 5px;
  font-weight: 400;
  height: 25em;
  border: 1px solid #ccc;
}

.email-client__tagbox {
  width: 100%;
  height: max-content;
}

.email-client__buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.email-client__button {
  background-color: #007bff;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 40%;
}

.email-client__button:hover {
  background-color: #0056b3;
}