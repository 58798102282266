.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5%;
  max-height: 100%;
  overflow-y: auto;
  overflow-y: auto;
}

.title {
  font-size: 50px;
  font-weight: bolder;
  align-self: center;
  margin-bottom: 5%;
}

.grid-admin {
  width: 100%;
  max-height: 100%;
}

.grid-header {
  font-size: 22px;
  font-weight: 500;
  padding-right: 25px;
}

.label {
  font-size: medium;
  font-weight: bold;
  margin-bottom: 10px;
}

.credit {
  font-size: 15px;
  background-color: #ededed;
  margin-bottom: 10px;
  height: 40px;
}

.button-group {
  display: flex;
  flex-direction: row;
}

.button {
  width: 100%;
  justify-content: center;
  align-self: center;
  margin: 10%;
}

.item-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.item-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  /* width: 300px; */
  padding: 15px;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  margin: 10px;
}

.item-title {
  font-size: 1.25rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 10px;
}

.api-key {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  width: 600px;
  padding: 15px;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.toggle-key-button {
  margin-left: 10px;
}

.card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  width: 300px;
  padding: 15px;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.card-text {
  font-size: 1rem;
  color: #666;
}

.score {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  width: 300px;
  padding: 15px;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.field-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.field-row label {
  flex: 2;
}

.field-row .dx-numberbox {
  flex: 2;
}

.field-row .dx-textbox {
  flex: 2;
}

.field-row .color-box {
  /* flex: 1; */
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
