.template-editor {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  padding: 5%;
  max-width: 100%;
  overflow-y: auto;
  height: 100%;
}

.template-editor__title {
  font-size: 40px;
  font-weight: bolder;
  align-self: center;
  margin-bottom: 10px;
}