@use "../node_modules/devextreme/scss/widgets/material/colors" as * with ($color: "blue", $mode: "light");
@use "../node_modules/devextreme/scss/widgets/material/sizes" as * with ($size: "compact");
@use '../node_modules/devextreme/scss/widgets/material/toolbar/sizes' as toolbar_sizes;
@use "../node_modules/devextreme/scss/widgets/material/button/mixins" as *;
@use "../node_modules/devextreme/scss/widgets/material/textEditor/colors" as *;
@use "../node_modules/devextreme/scss/widgets/material/fieldset/sizes" as *;
@use "../node_modules/devextreme/scss/widgets/material/list/sizes" as *;

@forward "../node_modules/devextreme/scss/widgets/material/colors";
@forward "../node_modules/devextreme/scss/widgets/material/textEditor/colors";

@use "sass:math";
@use "sass:color";

$toolbar-height: toolbar_sizes.$material-toolbar-height;
$header-toolbar-vertical-padding: 10px;
$toolbar-vertical-padding: 16px;
$content-padding: 16px;
$side-panel-min-width: 48px;
$side-panel-background: darken($base-bg, 5);
$background-color: $base-bg;
$accent-color: $base-accent;
$background-gray-color: color.change($base-text-color, $alpha: 0.04);
$filled-texteditor-input-horizontal-padding: $material-field-value-horizontal-padding;
$toolbar-margin-bottom: $content-padding;
$texteditor-edit-color: $texteditor-color;
$list-padding-left: $material-list-item-horizontal-padding;
$footer-height: 70px;
$footer-left-padding: math.div(($side-panel-min-width - $material-base-icon-size), 2);

@mixin separator() {
  .separator {
    height: 24px;
    width: 1px;
    background: $base-border-color;

    .dx-toolbar-menu-section & {
      height: 1px;
      width: auto;
    }
  }
}

@mixin header {
  font-size: 22px;
  font-weight: 500;
  padding-right: 25px;
}

@mixin user-info {
  .user-info {
    margin-bottom: 20px;
    display: flex;

    .photo {
      width: 124px;
      height: 124px;
      margin-right: 20px;
      border-radius: 8px;
      background-repeat: no-repeat;
      background-size: cover;
      box-shadow: 0 2px 8px 0 #00000014;
      border: 1px solid #e0e0e0;
    }
  }

  .title {
    font-size: 12px;
    color: $texteditor-label-color;

    &:not(:first-child) {
      padding-top: 10px;
    }
  }

  .value {
    font-size: 16px;
    color: $base-accent;

    &.black {
      color: $base-text-color;
    }

    &.small {
      font-size: 12px;
    }

    .dx-icon {
      vertical-align: bottom;
      padding: 10px 12px 0 0;
    }
  }
}

@mixin message-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  color: $texteditor-label-color;
}

@mixin message-text {
  padding-top: 5px;
  line-height: 21px;
}

@mixin status-contact($status-color) {
  color: $status-color;

  &.dx-texteditor {
    .status-editor-input {
      color: $status-color;
      padding-left: 0;
    }
  }

  span {
    &::before {
      background-color: $status-color;
    }
  }
}
