@import url("https://fonts.googleapis.com/css2family=Poppins:wght@300;400;500;600;700&display=swap");
/** Start Header CSS **/

.landing-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.landing-page-header .logo {
  display: flex;
  justify-content: center;
}

/** End Header Css **/

/** Start Hero Css **/

.hero-section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.hero-section .container-hero {
  /* margin: auto;
  margin-left: 20px;
  margin-right: 20px; */
}

.hero-section .content-hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hero-section .content-hero .left-side {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.hero-section .content-hero .left-side h1 {
  font-weight: 700;
  font-size: 70px;
  letter-spacing: -0.01em;
  line-height: 97px;
  text-align: left;
  color: #1e1c24;
}

.hero-section .content-hero .left-side p {
  width: 90%;
  font-weight: bold;
  font-size: 20px;
  line-height: 35px;
  text-align: left;
  color: #b1aaaa;
  margin-top: 15px;
}


.hero-section .content-hero .left-side .form-group {
  display: flex;
  align-items: center;
  /* margin-top: 35px; */
}


.hero-section .content-hero .right-side {
  width: 80%;
  position: relative;
  margin-top: 80px;
}

/**  End Hero Css **/

/** Start Serivces Css **/

.services {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  margin-left: 30px;
  margin-right: 30px;
}

.services .header-services {
  display: flex;
  align-items: center;
  justify-content: center;
}

.services .header-services h2 {
  font-weight: bold;
  font-size: 50px;
  text-align: center;
  color: #1e1c24;
}

.services .container-services {
  /* margin: auto; */
}

.services .grid-services {
  display: grid;
  grid-template-columns: repeat(3, 320px);
  justify-content: center;
  grid-gap: 30px;
  margin-top: 80px;
}

.services .grid-services .card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px;
  border-radius: 11px;
}

.services .grid-services .card .info {
  display: flex;
  flex-direction: column;
  position: relative;
  top: -20px;
}

.services .grid-services .card .info h3 {
  font-weight: normal;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
}

.services .grid-services .card .info p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  margin-top: 25px;
}

.services .grid-services .card:nth-of-type(1) .info h3 {
  color: #fff;
}

.services .grid-services .card:nth-of-type(1) .info p {
  color: #fff;
}

.services .grid-services .card:nth-of-type(2) .info h3 {
  color: #1e1c24;
}

.services .grid-services .card:nth-of-type(2) .info p {
  color: #1e1c24;
}

.services .grid-services .card:nth-of-type(3) .info h3 {
  color: #1e1c24;
}

.services .grid-services .card:nth-of-type(3) .info p {
  color: #1e1c24;
}

.services .grid-services .card:nth-of-type(1) {
  background: #038bc9;
}

.services .grid-services .card:nth-of-type(2) {
  background: #e9f0ff;
  position: relative;
  top: 50px;
}

.services .grid-services .card:nth-of-type(3) {
  background: #ffe7aa;
}

/** End Services Css **/

/** Start Management Css **/

.management {
  display: flex;
  flex-direction: column;
  padding: 100px 30px;
  margin-top: 100px
}

.management .container-management {
  /* margin: auto; */
}

.management .content-management {
  display: flex;
  flex-direction: column;
}

.management .content-management .item {
  display: flex;
  align-items: center;
}

.management .content-management .item:not(:last-child) {
  margin-bottom: 200px;
}

.management .content-management .item:nth-child(2n) {
  flex-direction: row-reverse;
}

.management .content-management .item .left-side {
  display: flex;
  flex-direction: column;
}

.management .content-management .item .left-side img {
  object-fit: contain;
}

.management .content-management .item .right-side {
  width: 500px;
  max-width: 95%;
  margin-left: 40px;
}

.management .content-management .item .right-side h3 {
  font-weight: bold;
  font-size: 45px;
  line-height: 55px;
  text-align: left;
  color: #1e1c24;
}

.management .content-management .item .right-side p {
  font-weight: bold;
  font-size: 16px;
  line-height: 27px;
  text-align: left;
  color: #b1aaaa;
  margin-top: 16px;
}

.management .content-management .item .right-side button {
  padding: 15px 40px;
  border-radius: 30px;
  background: #525ffb;
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: 400;
  font-size: 17px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #fff;
  margin-top: 20px;
}

/** End Management Css **/

/** Start Trust Us Css **/

.trustus {
  display: flex;
  flex-direction: column;
  padding: 100px 30px;
}

.trustus .header-trustus {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.trustus .header-trustus h2 {
  font-weight: bold;
  font-size: 50px;
  text-align: center;
  color: #1e1c24;
}

.trustus .container-trustus {
  /* margin: auto; */
}

.trustus .content-trustus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -10px;
}

.trustus .content-trustus h3 {
  font-weight: normal;
  font-size: 150px;
  line-height: 60px;
  text-align: center;
  color: #525ffb;
}

.trustus .content-trustus img {
  object-fit: contain;
}

/** End Trust Us Css **/

/** Start Footer Css **/

footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  border-top: 1px solid #e6e6e6;
}

footer .header-footer {
  display: flex;
  flex-direction: column;
}

footer .header-footer h3 {
  text-align: center;
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  color: #1e1c24;
  margin-bottom: 20px;
}

footer .header-footer h5 {
  width: 500px;
  max-width: 95%;
  font-weight: 300;
  font-size: 27px;
  line-height: 35px;
  text-align: center;
  color: #1e1c24;
  /* margin: auto; */
}

footer .signup-button {
  display: flex;
  align-items: center;
}

/** End Footer Css **/
